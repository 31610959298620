<template>
  <div class="boxpane roleManagement">
    <el-row :gutter="6" style="margin-bottom: 0">
      <el-col :span="5" :xl="5" :md="8" :sm="12" :xs="24" class="mb20">
        商务：
        <el-input
          style="width: 220px"
          v-model="searchData.account"
          placeholder="请输入商务ID/商务名称查询"
        ></el-input>
      </el-col>
      <el-col :span="5" :xl="5" :md="8" :sm="12" :xs="24" class="mb20">
        <el-button
          class="mr10"
          type="primary"
          icon="el-icon-search"
          @click="handleSearch()"
          >查询</el-button
        >
      </el-col>
    </el-row>
    <PageTable
      :columns="columns"
      :tableData="tableData"
      @page-change="handleChangePage"
    >
      <el-table-column label="操作" align="center">
        <template slot-scope="{ row }">
          <el-button
            type="text"
            class="operationBtn"
            @click="handleOperate(row)"
            >对接客户详情</el-button
          >
        </template>
      </el-table-column>
    </PageTable>
  </div>
</template>
<script>
// import Detail from "./Detail";
// import managePeople from "./managePeople";
// import PageTable from "./PageTable";
import PageTable from "@/components/PageTableSort";
import {
  getBusinessManage,
  // getRoleList,
  deleteRole,
  addRole,
  updateRole,
  getAllMenuList,
} from "./indexServe";
export default {
  name: "roleManagement",
  // components: { Detail, managePeople },
  components: { PageTable },
  mounted() {
    this.init();
  },
  data() {
    return {
      visibleDetail: false,
      searchData: {
        account: "",
      },

      tableData: {
        list: [],
      },
      columns: [
        { key: "id", label: "商务ID", align: "center" },
        { key: "name", label: "商务名称", align: "center" },
        { key: "businessCount", label: "客户数量", align: "center" },
      ],
      visible: false,
      operationType: "", // 记录当前操作模式新建-create、编辑-edit
      detailData: {},
      allMenuList: [], // 系统中所有菜单
      allLeafList: [], // 菜单所有叶子节点
      defaultCheckedList: [],
    };
  },
  methods: {
    // 事件监听
    handleChangePage({ page, size }) {
      this.getTableList({ page, size });
    },
    async handleOperate(row) {
      this.$emit("goDetail", row);
    },
    handleDelete(row) {
      deleteRole({ roleId: Number(row.id) }).then((res) => {
        if (res && res.code == 0) {
          this.getTableList({
            page: this.tableData.page,
            size: this.tableData.size,
          }); // 删除成功后刷新列表
          this.$message.success("删除成功");
        }
      });
    },
    handleSearch() {
      this.getTableList({ page: 1 });
    },
    handleSubmit(formData) {
      const fcn = this.operationType === "edit" ? updateRole : addRole; // 编辑和新建分别调用不同接口
      formData.permissions.forEach((item, index) => {
        formData.permissions[index] = Number(item);
      });
      let params = {
        name: formData.name,
        permissions: formData.permissions,
        remark: formData.businessCount,
      };
      if (this.operationType === "edit") {
        params.id = formData.id;
      }
      fcn(params).then((res) => {
        if (res && res.code == 0) {
          this.visible = false;
          let msg = this.operationType === "edit" ? "修改成功" : "新建成功";
          this.getTableList({
            page: this.tableData.page,
            size: this.tableData.size,
          }); // 成功后刷新列表
          this.$message.success(msg);
        }
      });
    },
    cancelDetail() {
      this.visible = false;
    },
    // 后端请求
    async getTableList(params, isDownload) {
      const res = await getBusinessManage({
        // const res = await getRoleList({
        page: 1,
        size: 10,
        ...this.searchData,
        ...params,
      });
      if (res && res.code == 0) {
        // 下载时，不需要更新到table
        if (!isDownload) {
          this.tableData = res.data;
        }
        return res.data;
      }
    },
    // 获取系统中所有菜单
    async getAllMenuList(params) {
      const res = await getAllMenuList(params);
      if (res && res.code == 0) {
        this.allMenuList = res.data.treeList;
        const allLeafList = this.filterAllLeaf(res.data.treeList);
        this.allLeafList = allLeafList;
        this.defaultCheckedList = res.data.idList;
        return res.data;
      }
    },
    // 其他
    init() {
      this.getTableList();
    },
    filterAllLeaf(list, leafList = []) {
      list.forEach((item) => {
        if (item.items && item.items.length !== 0) {
          this.filterAllLeaf(item.items, leafList);
        } else {
          leafList.push(item.id);
        }
      });
      return leafList;
    },
  },
};
</script>
<style lang="scss">
.boxpane {
  .pagination-wapper {
    margin-top: 30px;
  }
}
</style>
<style scoped lang="scss">
.roleManagement {
  .operationBtn {
    padding: 0;
  }
}
</style>
